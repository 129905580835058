<template>
    <v-container>
        <v-row class="mt-5">
            <v-col>
                <h2 class="my-2">Settings</h2>
                <TableProductionLines />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <TableIFCPSs :company="company" />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <TableBTLPSs :company="company" />
            </v-col>
        </v-row>
        <v-row>
            <v-col> <TableMachines :company="company" /> </v-col>
        </v-row>
    </v-container>
</template>

<script>
import TableProductionLines from "@/components/company/TableProductionLines";
import TableIFCPSs from "@/components/ifcps/TableIFCPSs";
import TableBTLPSs from "@/components/ifcps/TableBTLPSs";
import TableMachines from "@/components/company/TableMachines";
export default {
    components: {
        TableProductionLines,
        TableIFCPSs,
        TableBTLPSs,
        TableMachines,
    },
    data: () => ({}),
    computed: {
        company() {
            return this.$store.state.company.company;
        },
    },
    mounted() {},
};
</script>