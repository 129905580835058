<template>
    <div class="uppy-box"></div>
</template>


<script>
import bus from "@/utils/event_bus";
import { backend_api } from "@/utils/api";
import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import AwsS3 from "@uppy/aws-s3";
export default {
    data: () => ({
        uppy: null,
        uploading: false,
    }),
    computed: {
        company() {
            return this.$store.state.company.company;
        },
    },
    methods: {
        get_upload_parameters(file) {
            return backend_api
                .post("/sign_s3/", {
                    filename:
                        "companies/" + this.company.uuid + "/lite/" + file.name,
                    type: file.type,
                })
                .then((r) => r.data);
        },
        reloadUppy() {
            this.uppy = new Uppy({
                debug: true,
                restrictions: {
                    maxFileSize: 30000000,
                    maxNumberOfFiles: 30,
                    minNumberOfFiles: 1,
                },
            })
                .use(Dashboard, {
                    theme: this.$vuetify.theme.dark ? "dark" : "light",
                    target: ".uppy-box",
                    inline: true,
                    hideUploadButton: true,
                    // width: 1200,
                    height: 400,
                    proudlyDisplayPoweredByUppy: false,
                    locale: {
                        strings: {
                            dropPasteImport: "Drop .ifc, .jjs or .wup files.",
                        },
                    },
                })
                .use(AwsS3, {
                    metaFields: ["name"],
                    getUploadParameters: this.get_upload_parameters,
                });
            this.uppy.on("upload", () => (this.uploading = true));
            this.uppy.on("complete", (result) => {
                const timestamp = new Date();
                let files = result.successful.map((f) => {
                    let url = f.uploadURL;
                    let name = f.meta.key;
                    let short_name = f.name
                        ? f.name
                              .replace(".pdf", "")
                              .replace(".ifc", "")
                              .replace(".jjs", "")
                              .replace(".wup", "")
                              .replace(".docx", "")
                              .replace(".doc", "")
                              .replace(".xlsx", "")
                              .replace(".xsl", "")
                        : "no-name";
                    let file_object = {
                        url,
                        name,
                        short_name,
                        timestamp,
                    };
                    return file_object;
                });
                bus.$emit("notification", {
                    timeout: 5000,
                    text: `Uploaded files ${result.successful.length}. Failed files: ${result.failed.length}.`,
                    color: result.failed.length == 0 ? "success" : "error",
                });
                this.$emit("uploaded", files);
                this.uploading = false;

                // Cleaning uppy box
                this.uppy.reset();
            });
        },
        upload() {
            this.uppy.upload();
        },
    },
    mounted() {
        this.reloadUppy();
    },
};
</script>