 <template>
    <v-form @keyup.enter.native="save" ref="form">
        <v-text-field
            label="IFC Parsing Setup Name"
            v-model="form.name"
            outlined
            hide-hints
            :rules="[(v) => !!v || 'Name required']"
        >
        </v-text-field>
        <v-tabs color="accent" class="mb-2" v-model="tab">
            <v-tab> General </v-tab>
            <v-tab> Steel </v-tab>
            <v-tab> Custom </v-tab>
            <v-tabs-slider color="accent"></v-tabs-slider>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item eager>
                <FormIFCPSCats
                    ref="general_cats"
                    section="general"
                    v-model="form.setup"
                />
                <FormIFCPSProps
                    eager
                    ref="general_props"
                    section="general"
                    v-model="form.props"
                />
            </v-tab-item>
            <v-tab-item eager>
                <FormIFCPSCats
                    ref="steel_cats"
                    section="steel"
                    v-model="form.steel_setup"
                />
                <FormIFCPSProps
                    eager
                    ref="steel_props"
                    section="steel"
                    v-model="form.steel_props"
                />
            </v-tab-item>
            <v-tab-item eager>
                <FormIFCPSBranches
                    eager
                    ref="custom_branches"
                    section="custom"
                    v-model="form.custom_branches"
                />
            </v-tab-item>
        </v-tabs-items>
    </v-form>
</template>

<script>
import FormIFCPSCats from "./FormIFCPSCats";
import FormIFCPSProps from "./FormIFCPSProps";
import FormIFCPSBranches from "./FormIFCPSBranches";
export default {
    components: {
        FormIFCPSCats,
        FormIFCPSProps,
        FormIFCPSBranches,
    },
    props: ["value"],
    data: () => ({
        form: {},
        tab: 0,
    }),
    watch: {
        value: {
            handler: function (v) {
                this.form = v;
            },
            immediate: true,
        },
        form: {
            handler: function (f) {
                this.$emit("input", f);
            },
            deep: true,
        },
    },
    methods: {
        validate() {
            // This is used also by parent component
            return [
                this.$refs.general_cats,
                this.$refs.general_props,
                this.$refs.steel_cats,
                this.$refs.steel_props,
                this.$refs.custom_branches,
            ].every((ref) => ref.validate());
        },
        save() {
            if (this.validate()) {
                console.log("[i] Validated. Saving.");
                this.$emit("save");
            } else {
                console.log("[!] Not valid.");
            }
        },
    },
};
</script>