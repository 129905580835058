import { backend_api, paginated_factory } from '@/utils/api'


export function timeout_reload_bom(context, lj) {
    const status = lj.status
    if (["waiting", "working"].includes(status) || status.includes("working")) {
        console.log("Setting timeout:", lj.status)
        if (lj.uuid)
            setTimeout(() => context.dispatch("loadLiteJob", { uuid: lj.uuid }), 5000)
        else
            setTimeout(() => context.dispatch("loadLiteJobs"), 5000)
    } else {
        console.log("No timeout needed:", lj.status)
    }
}


export default {
    namespaced: true,
    state: {
        lite_jobs: [],
        d2f_licenses: [],
    },
    mutations: {
        setLiteJobs: (state, data) => state.lite_jobs = data,
        replaceJob: (state, data) => {
            let item = state.lite_jobs.find(lj => lj.uuid == data.uuid)
            Object.assign(item, { ...data })
        },
        createLiteJob: (state, lj) => state.lite_jobs.push(lj),
        updateLiteJob: (state, nlj) => {
            let i = state.lite_jobs.findIndex(lj => lj.uuid == nlj.uuid)
            state.lite_jobs[i] = nlj
            state.lite_jobs = [...state.lite_jobs]
        },
        deleteLiteJobByUuid: (state, lj_uuid) => state.lite_jobs = [...state.lite_jobs.filter(ps => ps.uuid != lj_uuid)],
        setD2FLicenses: (state, data) => state.d2f_licenses = data,
        createD2FLicense: (state, tl) => state.d2f_licenses.push(tl),
        updateD2FLicense: (state, ntl) => {
            let i = state.d2f_licenses.findIndex(tl => tl.uuid == ntl.uuid)
            state.d2f_licenses[i] = ntl
            state.d2f_licenses = [...state.d2f_licenses]
        },
        deleteD2FLicenseByUuid: (state, uuid) => {
            let i = state.d2f_licenses.findIndex(tl => tl.uuid === uuid)
            state.d2f_licenses.splice(i, 1)
        },
    },
    actions: {
        loadLiteJobs(context) {
            console.log("[i] Loading files")
            return paginated_factory('/lite/jobs/')()
                .then((data) => {
                    context.commit('setLiteJobs', data)
                    data.forEach(lj => {
                        timeout_reload_bom(context, lj)
                    })
                })
                .catch(e => console.log(`Cant load files: ${e}`))
        },
        loadLiteJob(context, payload) {
            return backend_api.get('/lite/jobs/' + payload.uuid + '/')
                .then(({ data }) => {
                    console.log("JOB STATUS:", data.status)
                    context.commit('replaceJob', data)
                    timeout_reload_bom(context, data)
                })
                .catch(e => console.log(`Cant load Job: ${e}`))
        },
        postLiteJob(context, lj) {
            return backend_api.post('/lite/jobs/', lj)
                .then(({ data }) => {
                    context.commit('createLiteJob', data)
                })
                .catch(e => console.log(`Cant create file: ${e}`))
        },
        putLiteJob(context, lj) {
            return backend_api.put('/lite/jobs/' + lj.uuid + "/", lj)
                .then(({ data }) => {
                    context.commit('updateLiteJob', data)
                })
                .catch(e => console.log(`Cant update file: ${e}`))
        },
        deleteLiteJob(context, lj_uuid) {
            return backend_api.delete('/lite/jobs/' + lj_uuid + '/')
                .then(() => {
                    context.commit('deleteLiteJobByUuid', lj_uuid)
                })
                .catch(e => console.log(`Cant delete file: ${e}`))
        },
        loadD2FLicenses(context) {
            return paginated_factory('/d2f_licenses/')()
                .then((data) => {
                    context.commit('setD2FLicenses', data)
                })
                .catch(e => console.log(`Cant get license: ${e}`))
        },
        postD2FLicense(context, tl) {
            return backend_api.post('/d2f_licenses/', tl)
                .then(({ data }) => {
                    context.commit('createD2FLicense', data)
                })
                .catch(e => console.log(`Cant create license: ${e}`))
        },
        putD2FLicense(context, tl) {
            return backend_api.put('/d2f_licenses/' + tl.uuid + "/", tl)
                .then(({ data }) => {
                    context.commit('updateD2FLicense', data)
                })
                .catch(e => console.log(`Cant update license: ${e}`))
        },
        deleteD2FLicense(context, tl_uuid) {
            return backend_api.delete('/d2f_licenses/' + tl_uuid + '/')
                .then(() => {
                    context.commit('deleteD2FLicenseByUuid', tl_uuid)
                })
                .catch(e => console.log(`Cant delete D2F license: ${e}`))
        },
    },
    getters: {}
}
