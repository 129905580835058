<template>
    <v-btn icon v-if="disabled" :href="url">
        <v-icon>mdi-download</v-icon>
    </v-btn>
</template>

<script>
export default {
    props: ["item"],
    computed: {
        disabled() {
            return !!this.item.metadata.output_file;
        },
        url() {
            return this.item.metadata.output_file?.presigned_url || "";
        },
    },
};
</script>