import { backend_api, paginated_factory } from '@/utils/api'

export default {
    namespaced: true,
    state: {
        company: {},
        production_sites: [],
        ifc_parsing_setups: [],
        btl_parsing_setups: [],
        machines: [],
    },
    mutations: {
        setCompany: (state, data) => state.company = data,
        setProductionSites: (state, data) => state.production_sites = data,
        createProductionSite: (state, ps) => state.production_sites.push(ps),
        updateProductionSite: (state, nps) => {
            let i = state.production_sites.findIndex(ps => ps.uuid == nps.uuid)
            state.production_sites[i] = nps
            state.production_sites = [...state.production_sites]
        },
        deleteProductionSiteByUuid: (state, ps_uuid) => state.production_sites = [...state.production_sites.filter(ps => ps.uuid != ps_uuid)],
        setIfcParsingSetups: (state, data) => state.ifc_parsing_setups = data,
        createIfcParsingSetup: (state, ips) => state.ifc_parsing_setups.push(ips),
        updateIfcParsingSetup: (state, nips) => {
            let i = state.ifc_parsing_setups.findIndex(ips => ips.uuid == nips.uuid)
            state.ifc_parsing_setups[i] = nips
            state.ifc_parsing_setups = [...state.ifc_parsing_setups]
        },
        deleteIfcParsingSetupByUuid: (state, ips_uuid) => state.ifc_parsing_setups = [...state.ifc_parsing_setups.filter(ps => ps.uuid != ips_uuid)],
        setBtlParsingSetups: (state, data) => state.btl_parsing_setups = data,
        createBtlParsingSetup: (state, bps) => state.btl_parsing_setups.push(bps),
        updateBtlParsingSetup: (state, nbps) => {
            let i = state.btl_parsing_setups.findIndex(bps => bps.uuid == nbps.uuid)
            state.btl_parsing_setups[i] = nbps
            state.btl_parsing_setups = [...state.btl_parsing_setups]
        },
        deleteBtlParsingSetupByUuid: (state, bps_uuid) => state.btl_parsing_setups = [...state.btl_parsing_setups.filter(ps => ps.uuid != bps_uuid)],
        setMachines: (state, data) => state.machines = data,
        createMachine: (state, machine) => state.machines.push(machine),
        updateMachine: (state, nmachine) => {
            let i = state.machines.findIndex(machine => machine.uuid == nmachine.uuid)
            state.machines[i] = nmachine
            state.machines = [...state.machines]
        },
        deleteMachineByUuid: (state, machine_uuid) => state.machines = [...state.machines.filter(ps => ps.uuid != machine_uuid)],
    },
    actions: {
        loadCompany(context) {
            return backend_api.get('/my_company/')
                .then(({ data }) => {
                    context.commit('setCompany', data)
                })
                .catch(e => console.log(`Cant load Compnay: ${e}`))
        },
        patchCompany(context, payload) {
            return backend_api.put('/my_company/', payload)
                .then(({ data }) => {
                    context.commit('setCompany', data)
                })
                .catch(e => console.log(`Cant patch company: ${e}`))
        },
        loadProductionSites(context) {
            return paginated_factory('/production_sites/')()
                .then((data) => {
                    context.commit('setProductionSites', data)
                })
                .catch(e => console.log(`Cant load production_sites: ${e}`))
        },
        postProductionSite(context, ps) {
            return backend_api.post('/production_sites/', ps)
                .then(({ data }) => {
                    context.commit('createProductionSite', data)
                })
                .catch(e => console.log(`Cant create production site: ${e}`))
        },
        putProductionSite(context, ps) {
            return backend_api.put('/production_sites/' + ps.uuid + "/", ps)
                .then(({ data }) => {
                    context.commit('updateProductionSite', data)
                })
                .catch(e => console.log(`Cant update production site: ${e}`))
        },
        deleteProductionSite(context, ps_uuid) {
            return backend_api.delete('/production_sites/' + ps_uuid + '/')
                .then(() => {
                    context.commit('deleteProductionSiteByUuid', ps_uuid)
                })
                .catch(e => console.log(`Cant delete production site: ${e}`))
        },
        loadIfcParsingSetups(context) {
            console.log("[i] Loading IFC Parsing Setups")
            return paginated_factory('/ifc_parsing_setups/')()
                .then((data) => {
                    context.commit('setIfcParsingSetups', data)
                })
                .catch(e => console.log(`Cant load IFC Parsing Setups: ${e}`))
        },
        postIfcParsingSetup(context, ips) {
            return backend_api.post('/ifc_parsing_setups/', ips)
                .then(({ data }) => {
                    context.commit('createIfcParsingSetup', data)
                })
                .catch(e => console.log(`Cant create IFC Parsing Setup: ${e}`))
        },
        putIfcParsingSetup(context, ips) {
            return backend_api.put('/ifc_parsing_setups/' + ips.uuid + "/", ips)
                .then(({ data }) => {
                    context.commit('updateIfcParsingSetup', data)
                })
                .catch(e => console.log(`Cant update IFC parsing setup: ${e}`))
        },
        deleteIfcParsingSetup(context, ips_uuid) {
            return backend_api.delete('/ifc_parsing_setups/' + ips_uuid + '/')
                .then(() => {
                    context.commit('deleteIfcParsingSetupByUuid', ips_uuid)
                })
                .catch(e => console.log(`Cant delete IFC parsing setup: ${e}`))
        },
        loadBtlParsingSetups(context) {
            console.log("[i] Loading BTL Parsing Setups")
            return paginated_factory('/btl_parsing_setups/')()
                .then((data) => {
                    context.commit('setBtlParsingSetups', data)
                })
                .catch(e => console.log(`Cant load BTL Parsing Setups: ${e}`))
        },
        postBtlParsingSetup(context, bps) {
            return backend_api.post('/btl_parsing_setups/', bps)
                .then(({ data }) => {
                    context.commit('createBtlParsingSetup', data)
                })
                .catch(e => console.log(`Cant create BTL Parsing Setup: ${e}`))
        },
        putBtlParsingSetup(context, bps) {
            return backend_api.put('/btl_parsing_setups/' + bps.uuid + "/", bps)
                .then(({ data }) => {
                    context.commit('updateBtlParsingSetup', data)
                })
                .catch(e => console.log(`Cant update BTL parsing setup: ${e}`))
        },
        deleteBtlParsingSetup(context, bps_uuid) {
            return backend_api.delete('/btl_parsing_setups/' + bps_uuid + '/')
                .then(() => {
                    context.commit('deleteBtlParsingSetupByUuid', bps_uuid)
                })
                .catch(e => console.log(`Cant delete BTL parsing setup: ${e}`))
        },
        loadMachines(context) {
            console.log("[i] Loading Machines")
            return paginated_factory('/machines/')()
                .then((data) => {
                    context.commit('setMachines', data)
                })
                .catch(e => console.log(`Cant load Machines: ${e}`))
        },
        postMachine(context, machine) {
            return backend_api.post('/machines/', machine)
                .then(({ data }) => {
                    context.commit('createMachine', data)
                })
                .catch(e => console.log(`Cant create Machine: ${e}`))
        },
        putMachine(context, machine) {
            return backend_api.put('/machines/' + machine.uuid + "/", machine)
                .then(({ data }) => {
                    context.commit('updateMachine', data)
                })
                .catch(e => console.log(`Cant update Machine: ${e}`))
        },
        deleteMachine(context, machine_uuid) {
            return backend_api.delete('/machines/' + machine_uuid + '/')
                .then(() => {
                    context.commit('deleteMachineByUuid', machine_uuid)
                })
                .catch(e => console.log(`Cant delete Machine: ${e}`))
        },
    },
    getters: {
        getProductionSiteByUuid: state => uuid => state.production_sites.find(ps => ps.uuid == uuid),
    }
}
