<template>
    <v-dialog v-model="dialog" width="400">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-if="icon != undefined"
                v-bind="attrs"
                v-on="on"
                icon
                large
                class="ml-2"
            >
                <v-icon> mdi-web </v-icon>
            </v-btn>
            <v-btn v-else v-bind="attrs" v-on="on" text color="accent">
                {{ $store.getters.t("language") }}
            </v-btn>
        </template>

        <v-card>
            <v-card-title>
                {{ $store.getters.t("language") }}
            </v-card-title>

            <v-card-text>
                {{ $store.getters.t("select_lang") }}
                <br />
                <br />
                <SelectLanguage :allow_default="true" v-model="language" />
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    :disabled="loading"
                    :loading="loading"
                    color="warning"
                    @click="save"
                >
                    {{ $store.getters.t("save") }}
                </v-btn>
                <v-btn color="secondary" @click="dialog = false">
                    {{ $store.getters.t("close") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
import SelectLanguage from "../account/SelectLanguage.vue";
import { backend_api } from "@/utils/api";
export default {
    props: ["icon"],
    components: { SelectLanguage },
    data: () => ({
        loading: false,
        dialog: false,
        language: "",
    }),
    computed: {
        user() {
            return this.$store.state.session.user_object;
        },
    },
    methods: {
        save() {
            this.loading = true;
            return backend_api
                .post("/my_user/change_language/", { language: this.language })
                .then(() => {
                    this.dialog = false;
                    this.$store.dispatch("session/getUserObject");
                })
                .catch((e) => console.log(`Cant change lang: ${e}`))
                .finally(() => (this.loading = false));
        },
    },
    mounted() {
        this.language = this.user.authorization.language;
    },
};
</script>