<template>
    <div>
        <v-dialog v-model="dialog" width="800">
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark v-bind="attrs" v-on="on">
                    {{ $store.getters.t(label) }}
                </v-btn>
            </template>

            <v-card>
                <v-card-title class="text-h5">
                    {{ $store.getters.t(label) }}
                    <v-spacer></v-spacer>
                    <v-btn small @click="add_row" color="accent" outlined>
                        <v-icon left small> mdi-plus </v-icon>
                        {{ $store.getters.t("add") }}
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    <div class="d-flex justify-space-between">
                        <div
                            class="mx-1 flex-grow"
                            v-for="col in columns"
                            :key="col.name"
                        >
                            {{ $store.getters.t(col.name) }}
                        </div>
                        <div class="mx-1 flex-grow"></div>
                    </div>
                    <div
                        class="d-flex justify-space-between"
                        v-for="(row, i) in table"
                        :key="i"
                    >
                        <div
                            class="ma-1"
                            v-for="(col, j) in columns"
                            :key="i + '_' + j"
                        >
                            <v-text-field
                                outlined
                                v-model="table[i][j]"
                                dense
                                hide-details
                                :suffix="col.suffix"
                                :rules="rules[j]"
                            >
                            </v-text-field>
                        </div>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="accent"
                            class="mt-2"
                            icon
                            small
                            @click="remove_row(i)"
                        >
                            <v-icon small> mdi-delete </v-icon>
                        </v-btn>
                    </div>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { get_rules_from_type } from "./utils";
export default {
    props: ["value", "label", "columns"],
    data: () => ({
        dialog: false,
        table: [],
    }),
    computed: {
        rules() {
            return this.columns.map(({ data_type }) =>
                get_rules_from_type(data_type)
            );
        },
    },
    watch: {
        value: {
            handler: function (v) {
                if (!v) v = [];
                this.table = v;
            },
            immediate: true,
        },
        table: {
            handler: function (table) {
                this.$emit("input", table);
            },
            immediate: true,
            deep: true,
        },
    },
    methods: {
        add_row() {
            this.table.push(new Array(this.columns.length).fill(""));
        },
        remove_row(i) {
            this.table.splice(i, 1);
        },
    },
};
</script>