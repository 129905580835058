<template>
    <v-icon :color="value ? 'green' : 'red'" small>
        {{ value ? "mdi-check-circle-outline" : "mdi-close-circle-outline" }}
    </v-icon>
</template>

<script>
export default {
    props: ["value"],
};
</script>