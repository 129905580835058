import Vue from 'vue'
import Vuex from 'vuex'
import companyModule from './company'
import sessionModule from './session'
import liteSpecModule from './litespec'
import translationsModule from './translations'
import { backend_api } from '@/utils/api'
import bus from "@/utils/event_bus"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        lock: false,  // Locks screen with loading overlay
        bootup: true, // Only at startup is true
    },
    mutations: {
        lock: state => state.lock = true,
        unlock: state => state.lock = false,
        endBootup: state => state.bootup = false,
    },
    actions: {
        refreshBaseData(context, lock) {
            if (lock) context.commit("lock");
            return Promise.all([
                context.dispatch('session/getUserObject'),
                context.dispatch("company/loadCompany"),
                context.dispatch("company/loadIfcParsingSetups"),
                context.dispatch("company/loadProductionSites"),
                context.dispatch("company/loadMachines"),
            ]).then(() => {
                if (lock) context.commit("unlock");
                if (context.state.bootup) {
                    context.commit("endBootup")
                }
            }).catch(() => { if (lock) context.commit("unlock") })
        },
        generateFiles(context, payload) {
            return backend_api.post('/lite/generate_files/', payload)
                .then(({ data }) => {
                    console.log("data", data)
                    bus.$emit("notification", {
                        timeout: 2000,
                        text: "Launched",
                        color: "info"
                    })
                    const lj = {
                        username: "---",
                        created: new Date(),
                        status: "waiting",
                        job_type: "mob",
                        metadata: payload
                    }
                    context.commit('litespec/createLiteJob', lj)
                    setTimeout(() => context.dispatch("litespec/loadLiteJobs"), 5000)
                })
                .catch(e => {
                    bus.$emit("notification", {
                        timeout: 5000,
                        text: "Can't launch generator:" + e,
                        color: "error"
                    })
                })
        },
    },
    getters: {
        t: (state, getters) => key => getters["translations/translate"](key)
    },
    modules: {
        company: companyModule,
        session: sessionModule,
        litespec: liteSpecModule,
        translations: translationsModule,
    }
})
