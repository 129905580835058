<template>
    <v-data-table
        :headers="headers"
        :items="jobs"
        item-key="uuid"
        :loading="loading ? 'accent' : false"
        :footer-props="{
            'items-per-page-options': [10, 25, 50, 100, -1],
        }"
        :items-per-page="50"
    >
        <template v-slot:item.status="{ item }">
            <FileToolTip :item="item" />
        </template>
        <template v-slot:item.created="{ item }">
            <Timestamp :value="item.created" />
        </template>
        <template v-slot:item.actions="{ item }">
            <v-row>
                <v-spacer></v-spacer>
                <FileDownloadButton :item="item" />
                <FileDeleteButton :item="item" />
                <ButtonJSONRender
                    title="Report metadata"
                    :json="item.metadata"
                />
            </v-row>
        </template>
        <template v-slot:no-data>
            <span>{{ $store.getters.t("no_files") }}</span>
        </template>
    </v-data-table>
</template>

<script>
import FileToolTip from "./FileToolTip";
import FileDeleteButton from "./FileDeleteButton";
import FileDownloadButton from "./FileDownloadButton";
import Timestamp from "@/components/renderers/Timestamp.vue";
import ButtonJSONRender from "@/components/renderers/ButtonJSONRender.vue";
export default {
    components: {
        FileToolTip,
        FileDeleteButton,
        FileDownloadButton,
        Timestamp,
        ButtonJSONRender,
    },
    data: () => ({ loading: false }),
    computed: {
        jobs() {
            return this.$store.state.litespec.lite_jobs;
        },
        headers() {
            // status
            return [
                {
                    text: this.$store.getters.t("document_type"),
                    value: "job_type",
                },
                {
                    text: this.$store.getters.t("status"),
                    value: "status",
                },
                {
                    text: this.$store.getters.t("built"),
                    value: "created",
                },
                {
                    text: this.$store.getters.t("user"),
                    value: "username",
                },
                {
                    text: this.$store.getters.t("actions"),
                    value: "actions",
                    sortable: false,
                    align: "right",
                },
            ];
        },
    },
    mounted() {
        this.loading = true;
        this.$store
            .dispatch("litespec/loadLiteJobs")
            .finally(() => (this.loading = false));
    },
};
</script>