<template>
    <v-card outlined>
        <v-card-title>
            D2F Licenses (Legacy Desktop Software)
            <v-spacer></v-spacer>
            <v-btn color="accent" v-if="can_add_more" text @click="new_license">
                <v-icon left> mdi-plus </v-icon>
                {{ $store.getters.t("new") }}
            </v-btn>
        </v-card-title>
        <v-card-subtitle>
            {{ $store.getters.t("active_licenses") }}: {{ active_count }}/{{
                company.max_d2f_licenses
            }}
        </v-card-subtitle>
        <v-card-text>
            <v-text-field
                label="Licenses search"
                outlined
                v-model="search"
            ></v-text-field
        ></v-card-text>
        <v-expand-transition>
            <v-simple-table>
                <thead>
                    <tr>
                        <th class="text-left">UUID</th>
                        <th class="text-left">
                            {{ $store.getters.t("company") }}
                        </th>
                        <th class="text-left">
                            {{ $store.getters.t("comment") }}
                        </th>
                        <th class="text-left">
                            {{ $store.getters.t("contact") }}
                        </th>
                        <th class="text-left">
                            {{ $store.getters.t("email") }}
                        </th>
                        <th class="text-left">
                            {{ $store.getters.t("active") }}
                        </th>
                        <th class="text-left">
                            {{ $store.getters.t("machine_hash") }}
                        </th>
                        <th class="text-left">
                            {{ $store.getters.t("created") }}
                        </th>
                        <th class="text-left">
                            {{ $store.getters.t("expiration") }}
                        </th>
                        <th class="text-right">
                            {{ $store.getters.t("actions") }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="license in d2f_licenses" :key="license.uuid">
                        <td>{{ license.uuid }}</td>
                        <td>{{ license.client_company }}</td>
                        <td>{{ license.comment }}</td>
                        <td>{{ license.contact }}</td>
                        <td>{{ license.email }}</td>
                        <td><IconBoolean :value="license.active" /></td>
                        <td>{{ license.machine_hash }}</td>
                        <td>
                            <Timestamp :value="license.created" />
                        </td>
                        <td>
                            <Timestamp :value="license.expiration" />
                        </td>
                        <td class="text-right">
                            <v-btn icon @click="launch_edit(license.uuid)">
                                <v-icon> mdi-pencil </v-icon>
                            </v-btn>
                            <v-btn icon @click="launch_delete(license.uuid)">
                                <v-icon> mdi-delete </v-icon>
                            </v-btn>
                            <v-btn
                                v-if="license.machine_hash != ''"
                                icon
                                @click="launch_unlock(license.uuid)"
                            >
                                <v-icon> mdi-lock-open-outline </v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </tbody>
                <v-dialog v-model="delete_dialog" width="500">
                    <v-card>
                        <v-card-title>
                            {{ $store.getters.t("delete_license") }}
                        </v-card-title>
                        <v-card-text>
                            {{ $store.getters.t("delete_license_confirm") }}
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer> </v-spacer>
                            <v-btn
                                @click="delete_license"
                                color="error"
                                :disabled="delete_loading"
                                :loading="delete_loading"
                            >
                                {{ $store.getters.t("delete") }}
                            </v-btn>
                            <v-btn
                                @click="delete_dialog = false"
                                color="secondary"
                            >
                                {{ $store.getters.t("close") }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="unlock_dialog" width="500">
                    <v-card>
                        <v-card-title>
                            {{ $store.getters.t("unlock_license") }}
                        </v-card-title>
                        <v-card-text>
                            {{ $store.getters.t("unlock_license_confirm") }}
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer> </v-spacer>
                            <v-btn
                                @click="unlock_license"
                                color="error"
                                :disabled="unlock_loading"
                                :loading="unlock_loading"
                            >
                                {{ $store.getters.t("unlock") }}
                            </v-btn>
                            <v-btn
                                @click="unlock_dialog = false"
                                color="secondary"
                            >
                                {{ $store.getters.t("close") }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialog" max-width="500">
                    <v-card>
                        <v-card-title>
                            {{ $store.getters.t("license") }}
                        </v-card-title>
                        <v-card-text class="my-2">
                            <v-form
                                @keyup.enter.native="post_license"
                                ref="d2fform"
                                v-model="valid"
                            >
                                <v-text-field
                                    class="mx-1"
                                    dense
                                    outlined
                                    :label="$store.getters.t('comment')"
                                    v-model="form.comment"
                                    :rules="[(v) => !!v]"
                                >
                                </v-text-field>
                                <v-combobox
                                    ref="clientCompanyComboBox"
                                    v-model="form.client_company"
                                    outlined
                                    dense
                                    :items="known_companies"
                                    :rules="[(v) => !!v]"
                                    no-data-text="No company"
                                    clearable
                                    class="mx-1"
                                    :label="$store.getters.t('client_company')"
                                ></v-combobox>
                                <v-text-field
                                    class="mx-1"
                                    dense
                                    outlined
                                    :label="$store.getters.t('contact')"
                                    v-model="form.contact"
                                >
                                </v-text-field>
                                <v-text-field
                                    class="mx-1"
                                    dense
                                    outlined
                                    :label="$store.getters.t('email')"
                                    v-model="form.email"
                                    :rules="emailRules"
                                >
                                </v-text-field>
                                <span> Expiration </span>
                                <v-date-picker
                                    landscape
                                    label="Expiration"
                                    v-model="form.expiration"
                                    color="accent"
                                ></v-date-picker>
                                <v-checkbox
                                    :disabled="!can_add_more && !form.active"
                                    dense
                                    outlined
                                    :label="$store.getters.t('active')"
                                    class="mx-1"
                                    v-model="form.active"
                                ></v-checkbox>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                @click="post_license"
                                color="primary"
                                :loading="saving"
                                :disabled="saving || !valid"
                            >
                                <v-icon left> mdi-check </v-icon>
                                {{ $store.getters.t("save") }}
                            </v-btn>
                            <v-btn color="secondary" @click="dialog = false">
                                {{ $store.getters.t("close") }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-simple-table>
        </v-expand-transition>
    </v-card>
</template>

<script>
const matchIt = function (param, search) {
    if (!param) return false;
    const s = search.toLowerCase().replace("-", "/").replace("_", "/");
    const p = String(param).toLowerCase().replace("-", "/").replace("_", "/");
    return p.includes(s);
};

import IconBoolean from "@/components/renderers/IconBoolean";
import Timestamp from "@/components/renderers/Timestamp.vue";
import _ from "lodash";
export default {
    components: {
        IconBoolean,
        Timestamp,
    },
    props: [],
    data: () => ({
        search: "",
        valid: false,
        saving: false,
        form: {},
        dialog: false,
        delete_dialog: false,
        delete_uuid: "",
        delete_loading: false,
        unlock_dialog: false,
        unlock_loading: false,
        emailRules: [
            (v) => /.+@.+/.test(v) || v == "" || "E-mail must be valid",
        ],
    }),
    computed: {
        d2f_licenses() {
            const s = this.search.toLowerCase();
            return this.$store.state.litespec.d2f_licenses.filter(
                (lic) =>
                    matchIt(lic.uuid, s) ||
                    matchIt(lic.client_company, s) ||
                    matchIt(lic.comment, s) ||
                    matchIt(lic.contact, s) ||
                    matchIt(lic.email, s) ||
                    matchIt(lic.created, s) ||
                    matchIt(lic.expiration, s) ||
                    matchIt(lic.machine_hash, s)
            );
        },
        active_count() {
            return this.d2f_licenses.filter((d2fl) => d2fl.active).length;
        },
        company() {
            return this.$store.state.company.company;
        },
        can_add_more() {
            return this.active_count < this.company.max_d2f_licenses;
        },
        known_companies() {
            return this.d2f_licenses.map((d2fl) => d2fl.client_company);
        },
    },
    methods: {
        launch_edit(uuid) {
            let license = this.d2f_licenses.find((s) => s.uuid == uuid);
            this.form = _.cloneDeep(license);
            // Adapt to datepicker
            this.form.expiration = this.form.expiration.substr(0, 10);
            this.dialog = true;
        },
        launch_delete(uuid) {
            this.delete_uuid = uuid;
            this.delete_dialog = true;
        },
        delete_license() {
            this.delete_loading = true;
            this.$store
                .dispatch("litespec/deleteD2FLicense", this.delete_uuid)
                .then(() => {
                    this.delete_loading = false;
                    this.delete_dialog = false;
                });
        },
        launch_unlock(uuid) {
            this.unlock_dialog = true;
            this.unlock_uuid = uuid;
        },
        unlock_license() {
            this.unlock_loading = true;
            console.log("Updating license");
            // let license = this.d2f_licenses.find((s) => s.uuid == uuid);
            // this.form = _.cloneDeep(license);
            this.$store
                .dispatch("litespec/putD2FLicense", {
                    uuid: this.unlock_uuid,
                    machine_hash: "",
                })
                .then(() => {
                    this.unlock_loading = false;
                    this.unlock_dialog = false;
                });
        },
        new_license() {
            this.form = {
                comment: "",
                client_company: "",
                active: true,
                expiration: new Date(
                    new Date().setFullYear(new Date().getFullYear() + 1)
                )
                    .toISOString()
                    .substr(0, 10),
            };
            this.dialog = true;
        },
        post_license() {
            if (!this.$refs.d2fform.validate()) return;
            this.$refs.clientCompanyComboBox.updateSelf();
            this.saving = true;
            if (this.form.uuid) {
                console.log("Updating license");
                this.$store
                    .dispatch("litespec/putD2FLicense", this.form)
                    .then(() => {
                        this.saving = false;
                        this.dialog = false;
                    });
            } else {
                console.log("Creating license");
                this.$store
                    .dispatch("litespec/postD2FLicense", this.form)
                    .then(() => {
                        this.saving = false;
                        this.dialog = false;
                    });
            }
        },
    },
    mounted() {
        this.$store.dispatch("litespec/loadD2FLicenses");
    },
};
</script>