import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import en from '@/translations/en'
import es from '@/translations/es'
import nl from '@/translations/nl'
import pl from '@/translations/pl'

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: { en, es, nl, pl },
        current: 'en',
    },
    theme: {
        dark: window.matchMedia('(prefers-color-scheme: dark)').matches,
    }
});
