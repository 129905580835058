
let INITIAL_STATE = {
}

if (process.env.NODE_ENV !== 'development') {
    INITIAL_STATE.VUE_APP_ROOT_API = 'https://produuz.it/api/v2/'
} else {
    INITIAL_STATE.VUE_APP_ROOT_API = 'http://localhost:8181/api/v2/'
}


export default INITIAL_STATE
