<template>
    <v-row>
        <v-col> <div class="uppy-machine-file"></div> </v-col>
        <v-col>
            <v-expansion-panels flat popup>
                <v-expansion-panel :key="k" v-for="(v, k) in value">
                    <v-expansion-panel-header>
                        {{ k || "main" }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content v-if="typeof v === 'object'">
                        <v-text-field
                            v-for="(v2, k2) in v"
                            :key="k2"
                            :label="k2"
                            :value="v2"
                            dense
                            disabled
                            outlined
                            hide-hints
                        >
                        </v-text-field>
                    </v-expansion-panel-content>
                    <v-expansion-panel-content v-else>
                        <v-text-field
                            :label="k"
                            :value="v"
                            dense
                            disabled
                            outlined
                            hide-hints
                        >
                        </v-text-field>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-col>
    </v-row>
</template>

<script>
import { backend_api } from "@/utils/api";
import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import AwsS3 from "@uppy/aws-s3";
import { v4 as uuidv4 } from "uuid";
export default {
    props: ["machine_model", "value"],
    data: () => ({
        uppy: null,
        loading: false,
    }),
    computed: {
        company() {
            return this.$store.state.company.company;
        },
    },
    watch: {
        value() {
            this.uppy.close();
            this.reloadUppy();
        },
    },
    methods: {
        get_upload_parameters(file) {
            const filename = `companies/${
                this.company.uuid
            }/machine_files/${uuidv4()}_${file.name}`;
            return backend_api
                .post("/sign_s3/", {
                    filename,
                    type: file.type,
                })
                .then((r) => r.data);
        },
        parse_file(payload) {
            console.log("[i] Parsing");
            return backend_api
                .post("/parse_machine_file/", payload)
                .then((r) => this.$emit("input", r.data));
        },
        reloadUppy() {
            this.uppy = new Uppy({
                debug: true,
                restrictions: {
                    maxFileSize: 30000000,
                    maxNumberOfFiles: 1,
                    minNumberOfFiles: 1,
                    // allowedFileTypes: ["*.xml"],
                },
            })
                .use(Dashboard, {
                    theme: this.$vuetify.theme.dark ? "dark" : "light",
                    target: ".uppy-machine-file",
                    inline: true,
                    hideUploadButton: false,
                    width: 350,
                    height: 200,
                    proudlyDisplayPoweredByUppy: false,
                })
                .use(AwsS3, {
                    metaFields: ["name"],
                    getUploadParameters: this.get_upload_parameters,
                });
            this.uppy.on("complete", (result) => {
                let f = result.successful[0];
                let url = f.uploadURL;
                let name = f.meta.key;
                let payload = { name, url, machine_model: this.machine_model };
                this.$emit("config_file_update", payload);
                this.parse_file(payload);
            });
        },
    },
    mounted() {
        this.reloadUppy();
    },
};
</script>