<template>
    <v-container>
        <v-row class="mt-5">
            <v-col cols="6">
                <h2 class="my-2">Upload</h2>
                <UploadBox ref="uppy" @uploaded="generate" />
            </v-col>
            <v-col cols="6" height="700">
                <h2 class="my-2">Setup</h2>
                <FormGenerateFiles ref="form" />
                <div class="d-flex flex-row mt-2">
                    <v-spacer></v-spacer>
                    <v-btn @click="upload" color="accent">
                        <v-icon left> mdi-progress-upload </v-icon> GENERATE
                    </v-btn>
                </div>
            </v-col>
        </v-row>
        <v-row class="mt-5">
            <v-col>
                <h2 class="my-2">Files</h2>
                <FilesTable />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import UploadBox from "@/components/litespec/UploadBox";
import FormGenerateFiles from "@/components/litespec/FormGenerateFiles";
import FilesTable from "@/components/litespec/FilesTable";
export default {
    components: { UploadBox, FormGenerateFiles, FilesTable },
    methods: {
        upload() {
            this.$refs.uppy.upload();
        },
        generate(files) {
            this.$refs.form.generate(files);
        },
    },
};
</script>