<template>
    <v-card outlined>
        <v-card-title>
            <v-btn text @click="expanded = !expanded">
                <v-icon v-if="expanded"> mdi-chevron-up </v-icon>
                <v-icon v-else> mdi-chevron-down </v-icon>
                {{ $store.getters.t("btl_parsing_setups") }}
            </v-btn>
            <v-spacer></v-spacer>
            <ButtonJSONUpload @uploaded="uploaded" v-if="expanded" />
            <v-btn
                v-if="expanded"
                :loading="loading_button"
                color="accent"
                text
                @click="new_btlps"
            >
                <v-icon left> mdi-plus </v-icon>
                {{ $store.getters.t("new") }}
            </v-btn>
        </v-card-title>
        <v-expand-transition>
            <v-simple-table v-if="expanded">
                <thead>
                    <tr>
                        <th class="text-left">UUID</th>
                        <th class="text-left">
                            {{ $store.getters.t("name") }}
                        </th>
                        <!-- <th class="text-left">
                        {{ $store.getters.t("set_at_projects") }}
                    </th> -->
                        <th class="text-right">
                            {{ $store.getters.t("actions") }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="ips in btl_parsing_setups" :key="ips.uuid">
                        <td>{{ ips.uuid.substring(0, 8) }}</td>
                        <td>{{ ips.name }}</td>
                        <!-- <td> {{}} </td> -->
                        <td class="text-right">
                            <v-btn
                                icon
                                @click="launch_edit(ips.uuid)"
                                :disabled="ips.read_only"
                            >
                                <v-icon> mdi-pencil </v-icon>
                            </v-btn>
                            <ButtonJSONDownload :json="ips" prefix="btlps" />
                            <v-btn icon @click="launch_clone(ips.uuid)">
                                <v-icon> mdi-content-copy </v-icon>
                            </v-btn>
                            <v-btn
                                icon
                                @click="launch_delete(ips.uuid)"
                                :disabled="ips.read_only"
                            >
                                <v-icon> mdi-delete </v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </tbody>
                <v-dialog v-model="delete_dialog" width="500">
                    <v-card>
                        <v-card-title>
                            {{ $store.getters.t("delete_btl_parsing_setup") }}
                        </v-card-title>
                        <v-card-text>
                            {{ $store.getters.t("this_ips_will_be_deleted") }}
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer> </v-spacer>
                            <v-btn
                                @click="delete_btlps"
                                color="error"
                                :disabled="loading_delete"
                                :loading="loading_delete"
                            >
                                {{ $store.getters.t("delete") }}
                            </v-btn>
                            <v-btn
                                @click="delete_dialog = false"
                                color="secondary"
                            >
                                {{ $store.getters.t("close") }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialog" max-width="1000">
                    <v-card>
                        <v-card-title>
                            {{ $store.getters.t("btl_parsing_setup") }}
                        </v-card-title>
                        <v-card-text class="py-3">
                            <FormBTLPSMain
                                ref="ips_form"
                                v-model="form"
                                @save="post_ips"
                            />
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                @click="post_ips"
                                color="primary"
                                :loading="saving"
                                :disabled="saving"
                            >
                                <v-icon left> mdi-check </v-icon>
                                {{ $store.getters.t("save") }}
                            </v-btn>
                            <v-btn color="secondary" @click="dialog = false">
                                {{ $store.getters.t("close") }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-simple-table>
        </v-expand-transition>
    </v-card>
</template>

<script>
import FormBTLPSMain from "@/components/ifcps/FormBTLPSMain";
import ButtonJSONUpload from "@/components/renderers/ButtonJSONUpload";
import ButtonJSONDownload from "@/components/renderers/ButtonJSONDownload";
import _ from "lodash";
import { backend_api } from "@/utils/api";
export default {
    components: { FormBTLPSMain, ButtonJSONUpload, ButtonJSONDownload },
    props: [],
    data: () => ({
        expanded: false,
        saving: false,
        form: {},
        dialog: false,
        delete_dialog: false,
        delete_uuid: "",
        loading_delete: false,
        loading_button: false,
    }),
    computed: {
        btl_parsing_setups() {
            return this.$store.state.company.btl_parsing_setups;
        },
    },
    methods: {
        launch_edit(uuid) {
            let ips = this.btl_parsing_setups.find((ips) => ips.uuid == uuid);
            this.form = _.cloneDeep(ips);
            this.dialog = true;
        },
        uploaded(btlps) {
            this.form = _.cloneDeep(btlps);
            this.dialog = true;
        },
        launch_clone(uuid) {
            let ips = this.btl_parsing_setups.find((ips) => ips.uuid == uuid);
            this.form = _.cloneDeep(ips);
            delete this.form.uuid;
            delete this.form.id;
            this.form.name = this.form.name + " [copy]";
            this.form.read_only = false;
            this.dialog = true;
        },
        launch_delete(uuid) {
            this.delete_uuid = uuid;
            this.delete_dialog = true;
        },
        delete_btlps() {
            this.loading_delete = true;
            this.$store
                .dispatch("company/deleteBtlParsingSetup", this.delete_uuid)
                .then(() => {
                    this.loading_delete = false;
                    this.delete_dialog = false;
                });
        },
        new_btlps() {
            this.loading_button = true;
            backend_api.get("/btl_ps_definitions/default/").then((data) => {
                this.form = { ...data };
                this.dialog = true;
                this.loading_button = false;
            });
        },
        post_ips() {
            if (!this.$refs.ips_form.validate()) return;
            this.saving = true;
            if (this.form.uuid) {
                console.log("Updating BTL Parsing Setup");
                this.$store
                    .dispatch("company/putBtlParsingSetup", this.form)
                    .then(() => {
                        this.saving = false;
                        this.dialog = false;
                    });
            } else {
                console.log("Creating BTL Parsing Setup");
                this.$store
                    .dispatch("company/postBtlParsingSetup", this.form)
                    .then(() => {
                        this.saving = false;
                        this.dialog = false;
                    });
            }
        },
    },
    mounted() {
        console.log("[i] IPS TABLE MOUNTED!");
        this.$store.dispatch("company/loadBtlParsingSetups");
    },
};
</script>