<template>
    <v-select
        outlined
        dense
        label="Language"
        :items="languages"
        v-model="language"
    ></v-select>
</template>

<script>
export default {
    props: ["value", "allow_default"],
    data: () => ({
        languages: [
            { text: "Dutch (nl)", value: "nl" },
            { text: "English (en)", value: "en" },
            { text: "Spanish (es)", value: "es" },
            { text: "Polish (pl)", value: "pl" },
        ],
        language: "",
    }),
    watch: {
        value: {
            handler: function (v) {
                this.language = v;
                console.log("immediate");
            },
            immediate: true,
        },
        language(lang) {
            this.$emit("input", lang);
        },
    },
    mounted() {
        if (this.allow_default) {
            this.languages.unshift({ text: "Default", value: null });
        }
    },
};
</script>