<template>
    <section id="part_props">
        <h2 class="my-2" v-if="part_props.length != 0">
            {{ $store.getters.t("part_props") }}
        </h2>
        <v-form ref="form">
            <div v-for="param in part_props" :key="param.value">
                <template v-if="param.value in props">
                    <div class="d-flex my-3">
                        <h4 class="mb-2 font-weight-light grey--text">
                            {{ param.text }}
                        </h4>
                        <v-spacer> </v-spacer>
                        <v-btn small text @click="add_item(param.value)">
                            <v-icon left> mdi-plus </v-icon>
                            ADD
                        </v-btn>
                    </div>
                    <div
                        class="text-center pa-3 grey"
                        :class="dark ? 'darken-4' : 'lighten-4'"
                        v-if="props[param.value].length == 0"
                    >
                        <span>
                            {{ $store.getters.t("ips_no_entry") }}
                        </span>
                    </div>
                    <div
                        class="d-flex"
                        v-for="(item, i) in props[param.value]"
                        :key="i + '_' + param.value"
                    >
                        <v-text-field
                            label="IFC Prop"
                            v-model="props[param.value][i]"
                            outlined
                            dense
                            hide-hints
                        >
                        </v-text-field>
                        <v-btn
                            small
                            class="ml-3 mt-1"
                            icon
                            @click="delete_item(param.value, i)"
                        >
                            <v-icon> mdi-minus </v-icon>
                        </v-btn>
                    </div>
                </template>
            </div>
        </v-form>
    </section>
</template>

<script>
import { backend_api } from "@/utils/api";
export default {
    props: ["value", "section"],
    data: () => ({
        loading: false,
        props: {},
        part_props: [],
    }),
    computed: {
        dark() {
            return this.$vuetify.theme.dark;
        },
    },
    watch: {
        value: {
            handler: function (props) {
                if (props) this.props = props;
                this.fill_empty_props();
            },
            immediate: true,
        },
        props: {
            handler: function (props) {
                this.$emit("input", props);
            },
            deep: true,
        },
    },
    methods: {
        add_item(param) {
            // mkey is "setup" or "props". It's the django model field.
            // param is the name of the param like "wood" or whatever.
            if (!this.props[param]) this.props[param] = [];
            this.props[param].push("");
        },
        delete_item(param, i) {
            this.props[param].splice(i, 1);
        },
        validate() {
            // This is used also by parent component
            return this.$refs.form.validate();
        },
        save() {
            if (this.validate()) this.$emit("save");
        },
        fill_empty_props() {
            this.part_props.forEach((param) => {
                if (!(param.value in this.props))
                    this.$set(this.props, param.value, []);
            });
        },
        fetch_part_props() {
            backend_api
                .get(`/ifc_ps_definitions/${this.section}/`)
                .then(({ data }) => {
                    this.part_props = [...data.part_props];
                    this.fill_empty_props();
                });
        },
    },
    mounted() {
        this.fetch_part_props();
    },
};
</script>