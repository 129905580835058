<template>
    <v-card outlined>
        <v-card-title>
            <v-btn text @click="expanded = !expanded">
                <v-icon v-if="expanded"> mdi-chevron-up </v-icon>
                <v-icon v-else> mdi-chevron-down </v-icon>
                {{ $store.getters.t("production_lines") }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn v-if="expanded" color="accent" text @click="new_site">
                <v-icon left> mdi-plus </v-icon>
                {{ $store.getters.t("new") }}
            </v-btn>
        </v-card-title>
        <v-expand-transition>
            <v-simple-table v-if="expanded" sort-by="name">
                <thead>
                    <tr>
                        <th class="text-left">UUID</th>
                        <th class="text-left">
                            {{ $store.getters.t("name") }}
                        </th>
                        <th class="text-left">
                            {{ $store.getters.t("zones") }}
                        </th>
                        <th class="text-left">
                            {{ $store.getters.t("stations") }}
                        </th>
                        <th class="text-right">
                            {{ $store.getters.t("actions") }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="site in production_sites" :key="site.uuid">
                        <td>{{ site.uuid.substring(0, 8) }}</td>
                        <td>{{ site.name }}</td>
                        <td>{{ site.zones }}</td>
                        <td>{{ site.stations.length }}</td>
                        <td class="text-right">
                            <!-- <ButtonSitesSortingDialog
                            icon
                            :production_site="site"
                        /> -->
                            <v-btn icon @click="launch_edit(site.uuid)">
                                <v-icon> mdi-pencil </v-icon>
                            </v-btn>
                            <v-btn icon @click="launch_clone(site.uuid)">
                                <v-icon> mdi-content-copy </v-icon>
                            </v-btn>
                            <v-btn icon @click="launch_delete(site.uuid)">
                                <v-icon> mdi-delete </v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </tbody>
                <v-dialog v-model="delete_dialog" width="500">
                    <v-card>
                        <v-card-title>
                            {{ $store.getters.t("delete_production_site") }}
                        </v-card-title>
                        <v-card-text>
                            {{ $store.getters.t("actions") }}
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer> </v-spacer>
                            <v-btn @click="delete_site" color="error" :disabled="delete_loading"
                                :loading="delete_loading">
                                {{ $store.getters.t("delete") }}
                            </v-btn>
                            <v-btn @click="delete_dialog = false" color="secondary">
                                {{ $store.getters.t("close") }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialog" max-width="1200">
                    <v-card>
                        <v-card-title>
                            {{ $store.getters.t("production_site") }}:
                            {{ form.name }}
                        </v-card-title>
                        <v-card-text>
                            <FormProductionLine ref="form" v-model="form" @save="post_site"
                                @touched_stations="reorder_stations" />
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn @click="post_site" color="primary" :loading="saving" :disabled="saving">
                                <v-icon left> mdi-check </v-icon>
                                {{ $store.getters.t("save") }}
                            </v-btn>
                            <v-btn color="secondary" @click="dialog = false">
                                {{ $store.getters.t("close") }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-simple-table>
        </v-expand-transition>
    </v-card>
</template>

<script>
import { by_name } from "@/utils/basics";
import FormProductionLine from "@/components/company/FormProductionLine";
import _ from "lodash";
// import { v4 as uuidv4 } from "uuid";
export default {
    components: {
        FormProductionLine,
    },
    props: [],
    data: () => ({
        expanded: false,
        saving: false,
        form: {},
        dialog: false,
        delete_dialog: false,
        delete_uuid: "",
        delete_loading: false,
    }),
    computed: {
        production_sites() {
            return [...this.$store.state.company.production_sites].sort(
                by_name
            );
        },
    },
    methods: {
        launch_edit(uuid) {
            let site = this.production_sites.find((s) => s.uuid == uuid);
            this.form = _.cloneDeep(site);
            this.form.stations = this.form.stations.sort(
                (a, b) => a.order - b.order
            );
            this.reorder_stations();
            this.dialog = true;
        },
        launch_clone(uuid) {
            let site = this.production_sites.find((s) => s.uuid == uuid);
            this.form = _.cloneDeep(site);
            this.form.stations = this.form.stations.sort(
                (a, b) => a.order - b.order
            );
            this.reorder_stations();
            this.form.stations.forEach((sta) => {
                delete sta.uuid
                sta.checklist_items.forEach(chi => delete chi.docs_package)
            });
            // this.form.uuid = uuidv4()
            delete this.form.uuid;
            delete this.form.id;
            this.dialog = true;
        },
        launch_delete(uuid) {
            this.delete_uuid = uuid;
            this.delete_dialog = true;
        },
        delete_site() {
            this.delete_loading = true;
            this.$store
                .dispatch("company/deleteProductionSite", this.delete_uuid)
                .then(() => {
                    this.delete_loading = false;
                    this.delete_dialog = false;
                });
        },
        new_site() {
            this.form = {
                name: "",
                stations: [],
                // uuid: uuidv4()
            };
            this.dialog = true;
        },
        reorder_stations() {
            let sta_count = this.form.stations.length;
            this.form.stations.forEach((sta, i) => {
                sta.is_last = i + 1 == sta_count;
                sta.order = i + 1;
            });
        },
        post_site() {
            if (!this.$refs.form.validate()) {
                return;
            }
            this.saving = true;
            if (this.form.uuid && this.form.id) {
                console.log("Updating Prod Site");
                this.$store
                    .dispatch("company/putProductionSite", this.form)
                    .finally(() => {
                        this.dialog = false;
                        this.saving = false;
                    });
            } else {
                console.log("Creating Prod Site");
                this.$store
                    .dispatch("company/postProductionSite", this.form)
                    .finally(() => {
                        this.dialog = false;
                        this.saving = false;
                    });
            }
        },
    },
    mounted() {
        console.log("MOUNTED!");
        this.$store.dispatch("company/loadProductionSites");
    },
};
</script>