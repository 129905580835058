var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.jobs,"item-key":"uuid","loading":_vm.loading ? 'accent' : false,"footer-props":{
        'items-per-page-options': [10, 25, 50, 100, -1],
    },"items-per-page":50},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
    var item = ref.item;
return [_c('FileToolTip',{attrs:{"item":item}})]}},{key:"item.created",fn:function(ref){
    var item = ref.item;
return [_c('Timestamp',{attrs:{"value":item.created}})]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-row',[_c('v-spacer'),_c('FileDownloadButton',{attrs:{"item":item}}),_c('FileDeleteButton',{attrs:{"item":item}}),_c('ButtonJSONRender',{attrs:{"title":"Report metadata","json":item.metadata}})],1)]}},{key:"no-data",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$store.getters.t("no_files")))])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }